/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

window.sr = ScrollReveal();

function isElementInViewport (el, offset) {

	//special bonus for those using jQuery
	if (typeof jQuery === "function" && el instanceof jQuery) {
		el = el[0];
	}

	var rect = el.getBoundingClientRect();

	return (rect.bottom >= 0 && rect.right >= 0 && (rect.top + offset)  <= (window.innerHeight || document.documentElement.clientHeight) && rect.left <= (window.innerWidth || document.documentElement.clientWidth));
}

(function($) {
	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Starter = {
		// All pages
		'common': {

			init: function() {

				var viewportWidth   = $(window).width();

				

				// PARALLAX
				var parallaxImages = [];

				$('.parallax-img-wrapper img').each(function() {
					parallaxImages.push($(this));
				});

				function updateParallaxImg() {
					$.each(parallaxImages, function(index, parallaxImage) {
						var scrolled 	= $(window).scrollTop();
						var wrapperTop 	= parallaxImage.parents('.parallax-wrapper').offset().top;

						if(! (wrapperTop < $(window).height())) {
							scrolled = (scrolled + $(window).height()) - wrapperTop;
						}

						if(isElementInViewport(parallaxImage, 0)) {
							var wrapperHeight 	= parallaxImage.parents('.parallax-wrapper').height(),
								imgHeight 		= parallaxImage.height(),
								result 			= imgHeight - wrapperHeight;

							if(result > Math.abs(scrolled * -0.3)) {
								parallaxImage.css({
									'transform': 'translate3d(0,' + (scrolled * -0.3).toFixed(2) + 'px, 0)'
								});
							}
						}
					});
				}

				$(window).on('scroll', function() {
					window.requestAnimationFrame(updateParallaxImg);
				});
				// -PARALLAX


				


				$( "body .block" ).last().addClass('lastblock');


				var mySVGsToInject = document.querySelectorAll('img.inject');
				new SVGInjector(mySVGsToInject);


				// Navigatie
				function toggleMenu() {
					$.scrollLock();
					$('.nav__open').toggleClass("is-active");
					$('.page-overlay').toggleClass("page-overlay--display");
					if( $('.nav__menu-collapse').hasClass('toggle') ) {
						if (viewportWidth < 768) {
							$('.nav__menu-collapse').removeClass("toggle").css('max-height', '');
						} else {
							$('.nav__menu-collapse').removeClass("toggle").css('max-width', '');
						}
					} else {
						if (viewportWidth < 768) {
							var MenuHeight = $('.nav__menu-wrapper').height();
							$('.nav__menu-collapse').addClass("toggle").css('max-height', MenuHeight);
						}else{
							var MenuWidth = $('.nav__menu-wrapper').width();
							$('.nav__menu-collapse').addClass("toggle").css('max-width', MenuWidth);
						}
					}
				}
				
				$('.nav__open').click(function(){
					toggleMenu();
				});

				$('.nav__close').click(function(){
					toggleMenu();
				});

				$('.menu-item').click(function(){
					$.scrollLock();
					$('.nav__open').toggleClass("is-active");
					$('.page-overlay').toggleClass("page-overlay--display");
					if( $('.nav__menu-collapse').hasClass('toggle') ) {
						if (viewportWidth < 768) {
							$('.nav__menu-collapse').removeClass("toggle").css('max-height', '');
						} else {
							$('.nav__menu-collapse').removeClass("toggle").css('max-width', '');
						}
					}
				});

				$('.page-overlay').click(function(){
					$.scrollLock();
					$('.nav__open').toggleClass("is-active");
					$('.page-overlay').toggleClass("page-overlay--display");

					if( $('.nav__menu-collapse').hasClass('toggle') ) {
						if (viewportWidth < 768) {
							$('.nav__menu-collapse').removeClass("toggle").css('max-height', '');
						} else {
							$('.nav__menu-collapse').removeClass("toggle").css('max-width', '');
						}
					}
				});
				// -Navigatie
			   

				function revealItems() {
					var $items = $('.item');

					$items.each(function() {
						var $el = $(this);

						if(isElementInViewport($el, 20)) {
							$el.addClass('item--visible');
						} else {
							// doe misschien iets?
						}
					});
				}


				$('.isotope-list').on( 'layoutComplete', function( event, laidOutItems ) {
					revealItems();
				});


				$(window).scroll(function() {
					// TODO: throttle toevoegen
					revealItems();
				}).trigger('scroll');
				

				// Isotope Partners
				var $containerpartners    	= $('#isotope-list--partners'),
					filters         		= {};
				$containerpartners.isotope({
					itemSelector      		: '.item--partner',
					percentPosition			: true,
					layoutMode				: 'packery',
					packery: {
						columnWidth: '.grid-sizer--partners'
					}
				});

				$containerpartners.imagesLoaded().progress( function() {
					$containerpartners.isotope('layout');
				});
				// -Isotope partners


				// Isotope Werk
				var $containerworks    		= $('#isotope-list--works'),
					filters         		= {};
				$containerworks.isotope({
					layoutMode 				: 'masonry',
					itemSelector      		: '.item--work',
					percentPosition			: true,
					masonry: {
						columnWidth 		: '.grid-sizer--works'
					}
				});

				$containerworks.imagesLoaded().progress( function() {
					$containerworks.isotope('layout');
				});
				// -Isoptope Werk


				// Isotope Winkel
				var $containerproducts  	= $('#isotope-list--products'),
					filters         		= {};
				$containerproducts.isotope({
					itemSelector      		: '.item--product',
					percentPosition			: true,
					layoutMode 				: 'fitRows',
				});

				$containerproducts.imagesLoaded().progress( function() {
					$containerproducts.isotope('layout');
				});

				$('.item__image-wrapper--shop').matchHeight();

				$.fn.matchHeight._afterUpdate = function(event, groups) {
				   $containerproducts.isotope('layout');
				}
				// -Isotope Winkel

				
				// Isotope filter
				// TODO Overbodige code voor dubbele filter weghalen.
				var $container      = $('.isotope-list'); //The ID for the list with all the posts
					filters         = {};


				$('.filters-select>ul>li').click(function() {
					$('#collapsefilter1').collapse('hide');
					
					if ( $(this).hasClass('selected') ) {
						return false;
					} else {
						var $optionSet    = $(this).parents('.filter__wrapper');
						$optionSet.find('.selected').removeClass('selected');
						$(this).addClass('selected');
					}

					var $this         	= $(this);
					var group         	= $this.parent().data('filter-group');
					filters[ group ]  	= $this.data('filter-value');
					var termname 		= $this.data('name');
					var isoFilters    	= [];
					

					$('.filter__wrapper').find('.filter__btn-p').text(termname);

					for ( var prop in filters ) {
						isoFilters.push( filters[ prop ] );
					}

					var selector      = isoFilters.join('');
					$container.isotope({ filter: selector });
					return false;
				});


				$(document).click(function(event) {
					if(!$(event.target).closest($('.filter__wrapper')).length) {
						$('#collapsefilter1').collapse('hide');
					}
				});
				// -Isotope filter


				
				


				// Uitklappertje
				$('.text__collapsedtext').on('shown.bs.collapse hidden.bs.collapse', function(){
					$(window).trigger('resize');
				});

				$('.text__buttoncollapse').click(function(){
					var nextText = $(this).closest('p.text__buttoncollapse').next('div.text__collapsedtext');
					
					$(this).toggleClass("collapsed");
					nextText.collapse('toggle');
				});
				// -Uitkappertje


				// Class om geanimeerde verschuiving in tekstgedeelte te krijgen
				if($(window).width() > 767) {
					$('.block--singleblog').addClass('active');

					$(window).on('scroll', function(e) {
						if ($(this).scrollTop() > 300) {
							$('.block--singleblog').removeClass('active');
						}else {
							$('.block--singleblog').addClass('active');
						}
					}).trigger('scroll');
				}
				// -Class verschuiving

			}
		},

		'home': {
			init: function() {

				var hashTagActive = "";
				$(".scrollclick").click(function (event) {
					event.preventDefault();
					//calculate destination place
					var dest  = 0;
					if ($(this.hash).offset().top > $(document).height() - $(window).height()) {
						dest  = $(document).height() - $(window).height();
					} else {
						dest  = $(this.hash).offset().top;
					}
					//go to destination
					$('html,body').animate({
						scrollTop: dest -40
					}, 500, 'swing');
					hashTagActive = this.hash;
				});
				
				$(window).on('resize', function(){
					if($(window).width() < 479) {
						var taglineHeight 	= $('.block__row--tagline').height();
						$('.block__row.block__row--r-swiper').css('height', 'calc(400px - ' + taglineHeight + 'px)');
					}else if($(window).width() > 991) {
						var taglineHeight 	= $('.block__row--tagline').height();
						$('.block__row.block__row--r-swiper').css('height', 'calc(90vh - ' + taglineHeight + 'px)');
					}else{
						var taglineHeight 	= $('.block__row--tagline').height();
						$('.block__row.block__row--r-swiper').css('height', 'calc(600px - ' + taglineHeight + 'px)');
					}
				}).trigger('resize');


				// Random knop introblock
				var $swiperHeader 	= $('.swiper-container--header').find('.swiper-button-info__content');
				var $swiper       	= $('.swiper-container--header');
				var	swiperSlides	= $swiper.attr('data-slides');
				var prev 			= 0;

				function generateNumber() {
					var random = Math.floor(Math.random() * swiperSlides);
					while (random == prev) {
						random = Math.floor(Math.random() * swiperSlides);
					}
					prev = random;
					return random;
				}


				var swiper = new Swiper('.swiper-container--header', {
					spaceBetween:    	0,
					centeredSlides:  	true,
					loop:       		false,
					autoplay: 			false,
					effect: 			"fade",
					preventClicks: 		true,
					initialSlide: 		generateNumber(swiperSlides),
					onInit: function(swiper) {
						$('.swiper-button-refresh').click(function() {
							var randomSlide = generateNumber(swiperSlides);
							swiper.slideTo(randomSlide);
						});

						var $activeSlide 	= $(swiper.slides[swiper.activeIndex]);
						var info 			= $activeSlide.find('.swiper-slide__caption').html();
						$swiperHeader.html(info);
					},
					onSlideChangeStart: function(swiper) {
						var $activeSlide 	= $(swiper.slides[swiper.activeIndex]);
						var info 			= $activeSlide.find('.swiper-slide__caption').html();
						$swiperHeader.html(info);
					}
				});

				$('.infobtn').click(function(){
					$('.swiper-button-info__content').collapse('toggle');
					$('.swiper-button-info').toggleClass('uitgeklapt');
					$(this).toggleClass("is-active");
				});


				// if($(window).width() > 1024) {
				//     $('.block--head').css('margin-bottom', $('.block--head-tagline').height());
				//     $('.block--head-tagline').addClass('active');

				//     $(window).on('scroll', function(e) {
				//         var messagePos 		= $('.block--head-tagline').offset().top;
				//        	var headerHeight 	= $('.block--head').height();

				//         if(messagePos >= headerHeight) {
				//             $('.block--head-tagline').removeClass('active');
				//             $('.block--head').css('margin-bottom', 0);
				//         }
				//     }).trigger('scroll');

				//     $(window).on('resize', function(e) {
				//         if($('.block--head-tagline').hasClass('active')) {
				//            	$('.block--head').css('margin-bottom', $('.block--head-tagline').height());
				//         }
				//     });
				// }

				if($(window).width() > 767) {
					if ($('.block--head').length){
						sr.reveal('.head__title', {
							duration: 		500,
							viewFactor: 	0.2,
							scale: 			0.2,
							origin: 		'left',
							distance: 		'2000px',
							mobile: 		false,
						});
					
						sr.reveal('.head__gallery-wrapper', {
							duration: 		800,
							viewFactor: 	0.2,
							scale: 			0.2,
							origin: 		'top',
							distance: 		'2000px',
							mobile: 		false,
						});
					
						sr.reveal('.head__logo-wrapper', {
							duration: 		800,
							viewFactor: 	0.2,
							scale: 			0.2,
							origin: 		'left',
							distance: 		'2000px',
							mobile: 		false,
						});
					}
				}


				$(window).on('scroll', function(e) {
					if ($(this).scrollTop() > 300) {
						$('.footer__logo').removeClass('footer__logo--home');
					}else {
						$('.footer__logo').addClass('footer__logo--home');
					}
				}).trigger('scroll');


			},
			finalize: function() {
			  // JavaScript to be fired on the home page, after the init JS
			}
		},
		'single_beeldserie': {
			init: function() {
					
				var $swiperGallery 	= $('.swiper-container--gallery'),
					$swiperCaption 	= $('.swipernav--gallery').find('.swiper-description'),

				slider = new Swiper('.swiper-container--gallery', {
					loop: true,
					// spaceBetween: 30,
					centeredSlides: true,
					hashnav: true,
					replaceState: true,
					// history: 'slides',
					// autoplay: false,
					// autoplayDisableOnInteraction: false,
					preloadImages: false,
					lazyLoading: true,
					lazyLoadingInPrevNext: true,
					nextButton: '.swiper-button-next',
					prevButton: '.swiper-button-prev',
					pagination: '.swiper-pagination',
					paginationType: 'custom',
					paginationClickable: true,
					keyboardControl: true,
					onInit: function(swiper){
						var description = $(swiper.slides[swiper.activeIndex]).find('.swiper-slide__caption').html();
						$swiperCaption.html(description);
					},
					onSlideChangeStart: function(swiper) {
						var description = $(swiper.slides[swiper.activeIndex]).find('.swiper-slide__caption').html();
						$swiperCaption.html(description);
					},
					paginationCustomRender: function (swiper, current, total) {
						if(current < 10) {
							current = '0' + current;
						}
						if(total < 10) {
							total = '0' + total;
						}
						return '<span class="current">' + current + '<br>' + '</span><span class="total">' + total + '</span>';
					}
				});


				$('.swiper-button-close').on('click', function(e) {
					if (document.referrer.indexOf(window.location.host) !== -1) {
						history.go(-1);
						e.preventDefault();
					}
				});


				var paddingGallerybot = $('.swipernav--gallery').height() +10;

				function paddingGallery() {
					$('.block--gallery').css('padding-bottom', paddingGallerybot);
				}

				paddingGallery();

				$('.swiper-button-next').on('click', function(e) {
					paddingGallery();
				});

				$(window).on('resize', function(e) {
					paddingGallery();
				});


			},
			finalize: function() {
			  // JavaScript to be fired on the home page, after the init JS
			}
		},
		
		'single_werk': {
			init: function() {

				
				var introHeight = $('.block--singlework').height();
				
				if($(window).width() > 767) {
					$('.text-wrapper--workhead').css('margin-top', -introHeight);
				}

				$('.button--open-wrapper').click(function(){
					// $('.button--open').toggleClass("collapsed");
					$('.text__firstlines').toggleClass('intro-open');
					$(this).toggleClass('intro-btn-close');
					$('.text-wrapper--workhead-inner .text__content').collapse('toggle');
				});
			   
			},
			finalize: function() {
			  // JavaScript to be fired on the home page, after the init JS
			}
		},


	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Starter;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
		// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


